export const mixin = {
  methods: {
    valiImgType(str) {
      if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(str)) {
        return false;
      }
      return true;
    },
    valiIsNotVideo(str) {
      if (!/\.(mp4|MP4)$/.test(str)) {
        return true;
      }
      return false;
    },
    showImportPhoto() {
      if (this.$refs["importPhoto"]) {
        this.$refs["importPhoto"].showImportPhoto();
      }
    },
    hasImgFileInStr(str) {
      if (!str || str.trim().length < 1) {
        return false;
      }
      var arrays = str.split(",");
      if (arrays.length < 1) {
        return false;
      }
      if (
        !arrays.some(item => {
          return this.valiImgType(item);
        })
      ) {
        return false;
      }
      return true;
    }
  }
};

<template>
  <a-modal v-model="Import_model" @ok="importSolts" :footer="null" @cancel="closeModel">
    <div slot="closeIcon">
      <a-icon type="close" @click="close"></a-icon>
    </div>
    <div class="upload_view">
      <div v-if="fileName != ''" class="file_view">
        <img src="../assets/image/excel_ico.png" class="icon_view" />
        <label class="filename">{{ fileName }}</label>
      </div>
      <a-upload
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        accept=".xlsx"
        :customRequest="uploadFile"
      >
        <a-spin :spinning="Loading">
          <div class="ant-upload-text">
            上传excel表格
          </div>
        </a-spin>
      </a-upload>
    </div>

    <div class="mesg_view" v-if="lotsFileList.status == 1">
      <a-alert :message="successMessage" type="success" show-icon />
    </div>
    <div class="mesg_view" v-if="Warningtext != ''">
      <a-alert :message="Warningtext" type="warning" show-icon />
    </div>

    <div class="mesg_view" v-if="lotsFileList.status == 0">
      <a-alert
        :message="lotsFileList.failure.length + '条错误，请修改后全部重新导入'"
        type="warning"
        show-icon
      />
      <!--      <a-alert message="Warning text" type="warning" show-icon />-->
    </div>
    <div v-if="lotsFileList.status == 0">
      <a-table
        :columns="columns"
        :data-source="lotsFileList.failure"
        :pagination="false"
        bordered
      >
      </a-table>
    </div>
    <template slot="title">
      <div>
        <label>导入腕表</label>
         <a
          v-if="groupId==2"
          class="noTemplate"
          href="https://zenith-dev.oss-cn-shanghai.aliyuncs.com/template/upload/Zenith_SKU_Sample.xlsx"
          >没有模板?点击下载</a
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import API from "@/request/index";
const columns = [
  {
    title: "行数",
    dataIndex: "count",
    key: "count",
    width: 80
  },
  {
    title: "错误原因",
    dataIndex: "message",
    key: "message"
  }
];
export default {
  name: "importFile",
  props: {
    isImport: {
      type: Boolean,
      value: false
    },
    groupId: {
      type: String,
      value: ""
    }
  },
  watch: {
    isImport: {
      handler(varl) {
        this.Import_model = varl;
      }
    },

  },
  data() {
    return {
      errorColumns: [
        {
          num: "行数",
          dataIndex: "number",
          customRender: (text, record) => {
            return 1;
          }
        },
        {
          bcyy: "报错原因",
          dataIndex: "bcyy"
        }
      ],
      datas: [
        {
          number: "1",
          bcyy: "ssvsvsvs"
        },
        {
          bcyy: "sjlgjsgjs;gjs;jgs;jgs;jgs;",
          number: "3"
        }
      ],
      loading: false,
      columns,
      lotsFileList: {},
      Import_model: this.isImport,
      fileName: "",
      Loading: false,
      successMessage: "",
      Warningtext: ""
    };
  },
  methods: {
    /**
     * 上传文件
     */
    async uploadFile(e) {
      const formData = new FormData();
      formData.append("file", e.file);
      //formData.append("groupId", this.groupId);
      this.Loading = true;
      if(this.groupId === '4'){
        await API.importItemsDrink(formData)
        .then(res => {
          this.Loading = false;
          if (res.data.code == "0000") {
            this.lotsFileList = res.data.data;
            this.fileName = e.file.name;
            if (this.lotsFileList.success) {
              this.successMessage =
                "导入成功,总计导入" +
                this.lotsFileList.success[0].message +
                "条";
              this.$emit("importSuccess");
            }
          }
        })
        .catch(error => {
          this.Loading = false;
        });
      }else{
      await API.importItems(formData)
        .then(res => {
          this.Loading = false;
          if (res.data.code == "0000") {
            this.lotsFileList = res.data.data;
            this.fileName = e.file.name;
            if (this.lotsFileList.success) {
              this.successMessage =
                "导入成功,总计导入" +
                this.lotsFileList.success[0].message +
                "条";
              this.$emit("importSuccess");
            }
          }
        })
        .catch(error => {
          this.Loading = false;
        });
      }
    },
    /**
     * 提交数据
     */
    importSolts() {
      this.Import_model = false;
      this.$emit("importSolts");
    },
    close() {
      this.$emit("closeModel");
    },
      closeModel(){
      this.Import_model = false;
      this.$emit("closeModel");
    }
  }
};
</script>

<style scoped>
.noTemplate {
  text-decoration: underline;
  margin-left: 20px;
  font-size: 15px;
}
.file_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*padding: 10px 30px;*/
  align-items: center;
  background-color: #cccccc;
  margin-right: 20px;
  border-radius: 4px;
  width: 150px;
  height: 100px;
  /*background-image: url("../assets/image/excel_ico.png");*/
  /*background-size: cover;*/
}
.avatar-uploader {
  /*margin-top: 5px;*/
}
/*.file_view::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  top: 0;*/
/*  background-color: rgba(0, 0, 0, 0.5);*/
/*  z-index: 2;*/
/*}*/
.mesg_view {
  margin: 10px 0;
}
.file_view .icon_view {
  width: 50px;
  height: 60px;
}
.upload_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  margin-right: 0px;
  margin-bottom: 0px;
}
.filename {
  line-height: 20px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 90%;
  font-size: 13px;
}
/deep/ .ant-table-row-cell-break-word {
  text-align: center;
}
</style>
